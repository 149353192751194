import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';

import config from '../../site-config';

const PostLink = ({ post }) => {
  const { t, i18n } = useTranslation();
  const date = new Date(post.frontmatter.date);

  return (
    <div className="py-8">
      <h3>
        {post.frontmatter.title}
      </h3>
      <p className="py-2 text-gray-500 font-light">
        {t(
          'author',
          {
            author: post.frontmatter.author,
            date: date.toLocaleString(i18n.languages[0], config.dateFormat),
          }
        )}
      </p>
      <p className="py-2">
        {post.excerpt}
      </p>
      <div className="text-right">
        <Link to={post.frontmatter.slug}>
          {t('continueReading')}
        </Link>
      </div>
    </div>
  );
};

PostLink.propTypes = {
  post: PropTypes.object.isRequired,
};

export default PostLink;
