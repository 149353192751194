import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';

import Layout from '../components/Layout';
import PostLink from '../components/PostLink';
import SEO from '../components/SEO';

const PostPage = ({
  data,
}) => {
  if (typeof window === 'undefined') {
    return null;
  }

  const { t, i18n } = useTranslation();
  const { allMarkdownRemark } = data;
  const { edges: posts } = allMarkdownRemark;

  const filteredPosts = (
    posts
      .filter((edge) => !!edge.node.frontmatter.date)
      .filter((edge) => edge.node.frontmatter.language === i18n.languages[0])
  );

  return (
    <Layout>
      <SEO
        title={t('blog')}
        description={t('blogExplain')}
      />
      <section className="container py-8 w-3/5 mx-auto">
        <>
          <h2>
            {t('blog')}
          </h2>
          <p className="text-xl mt-2 font-light">
            {t('blogExplain')}
          </p>
        </>
        {filteredPosts.map((edge) => (
          <PostLink key={edge.node.id} post={edge.node} />
        ))}
        {filteredPosts.length <= 0 && (
          <div className="py-16">
            <h3 className="text-center text-gray-500">
              {t('noPosts')}
            </h3>
            <p className="text-center text-gray-500 text-xl mt-2 font-light">
              {t('noPostsChangeLanguage')}
            </p>
          </div>
        )}
      </section>
    </Layout>
  );
};

PostPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { visible: { eq: true } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            author
            date
            language
            slug
            title
          }
        }
      }
    }
  }
`;

export default PostPage;
